import { Component, OnInit } from '@angular/core';
import { CarService } from '../../../Shared/services/car.service';
import { ActivatedRoute } from '@angular/router';
import { Car } from '../../../Shared/models/car';

@Component({
  selector: 'app-detail-sidebar',
  templateUrl: './detail-sidebar.component.html',
  styleUrls: ['./detail-sidebar.component.scss']
})
export class DetailSidebarComponent implements OnInit {

  constructor(private carService: CarService, private route: ActivatedRoute) { }

  public car: Car;
  private carId: number;
  private sub: any;

  ngOnInit() {


    this.sub = this.route.params.subscribe(params => {
      let carCode = params['id'];
      var n = carCode.lastIndexOf('-');
      var result = carCode.substring(n + 1);
      this.carId = +result;
      this.carService.getCarById(this.carId).subscribe(result => this.car = result['data']);
    });
  }

}
