import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private titleSerice: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleSerice.setTitle("Contact - Ton van der Tuin Auto's");

    this.meta.updateTag({ property: 'og:image', content: "https://www.tonvandertuinautos.nl/assets/images/meta-image.jpg" });
    this.meta.updateTag({ property: 'og:title', content: "Contact - Ton van der Tuin Auto's" });
    this.meta.updateTag({ property: 'og:url', content: "https://www.tonvandertuinautos.nl/#/contact/" });
    this.meta.updateTag({ property: 'og:description', content: "In het aanbod van Ton van der Tuin vindt u dankzij zéér zorgvuldige selectie alleen de auto’s waar u eigenlijk naar op zoek bent. Met een sterke voorkeur voor de Duitse “premium brands”, en altijd auto’s met iets bijzonders." });
    this.meta.updateTag({ name: 'description', content: "In het aanbod van Ton van der Tuin vindt u dankzij zéér zorgvuldige selectie alleen de auto’s waar u eigenlijk naar op zoek bent. Met een sterke voorkeur voor de Duitse “premium brands”, en altijd auto’s met iets bijzonders." });

  }

}
