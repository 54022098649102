import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Pages/home/home.component';
import { ContactComponent } from './Pages/contact/contact.component';
import { HeroComponent } from './Pages/home/hero/hero.component';
import { CarDetailsComponent } from './Pages/home/hero/car-details/car-details.component';
import { CtaComponent } from './Pages/home/cta/cta.component';
import { CtaDetailsComponent } from './Pages/home/cta/cta-details/cta-details.component';
import { ContactCtaComponent } from './Shared/Components/contact-cta/contact-cta.component';
import { FooterComponent } from './Shared/Components/footer/footer.component';
import { ShowcaseComponent } from './Shared/Components/showcase/showcase.component';
import { CardItemComponent } from './Shared/Components/card-item/card-item.component';
import { LogoOverviewComponent } from './Shared/Components/logo-overview/logo-overview.component';
import { LogoItemComponent } from './Shared/Components/logo-overview/logo-item/logo-item.component';
import { NavigationComponent } from './Shared/Components/navigation/navigation.component';
import { ContactHeaderComponent } from './Pages/contact/contact-header/contact-header.component';
import { ContactFormComponent } from './Pages/contact/contact-form/contact-form.component';
import { ContactInformationComponent } from './Pages/contact/contact-information/contact-information.component';
import { CollectieComponent } from './Pages/collectie/collectie.component';
import { CollectionSoldComponent } from './Pages/collectie/collection-sold/collection-sold.component';
import { CollectionHeaderComponent } from './Pages/collectie/collection-header/collection-header.component';
import { AutoDetailComponent } from './Pages/auto-detail/auto-detail.component';
import { DetailHeaderComponent } from './Pages/auto-detail/detail-header/detail-header.component';
import { DetailNewCarsComponent } from './Pages/auto-detail/detail-new-cars/detail-new-cars.component';
import { InformationComponent } from './Pages/auto-detail/information/information.component';
import { DetailContactComponent } from './Pages/auto-detail/detail-contact/detail-contact.component';
import { DetailSidebarComponent } from './Pages/auto-detail/detail-sidebar/detail-sidebar.component';
import { OwlModule } from 'ngx-owl-carousel';
import { RegistrationService } from './Shared/services/registration.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LightboxModule } from 'ngx-lightbox';
import { Error404Component } from './Pages/error404/error404.component';
import { Error500Component } from './Pages/error500/error500.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    HeroComponent,
    CarDetailsComponent,
    CtaComponent,
    CtaDetailsComponent,
    ContactCtaComponent,
    FooterComponent,
    ShowcaseComponent,
    CardItemComponent,
    LogoOverviewComponent,
    LogoItemComponent,
    NavigationComponent,
    ContactHeaderComponent,
    ContactFormComponent,
    ContactInformationComponent,
    CollectieComponent,
    CollectionSoldComponent,
    CollectionHeaderComponent,
    AutoDetailComponent,
    DetailHeaderComponent,
    DetailNewCarsComponent,
    InformationComponent,
    DetailContactComponent,
    DetailSidebarComponent,
    Error404Component,
    Error500Component
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    OwlModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LightboxModule,
    ReactiveFormsModule
  ],
  providers: [RegistrationService, Title, { provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: LOCALE_ID, useValue: 'nl-NL' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
