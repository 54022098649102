import { Component, OnInit, Input } from '@angular/core';
import { CarService } from '../../services/car.service';
import { CarMedia } from '../../models/carMedia';

import { LOCALE_ID } from '@angular/core';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss']
})
export class CardItemComponent implements OnInit {

  constructor(private carService: CarService) { }

  @Input() car: any;

  public carMedia: CarMedia;
  public image: string;
  public carCode: string;

  ngOnInit() {
    this.carService.getPrimaryImage(this.car.id).subscribe(result => this.registerData(result['data']));
    
  }

  registerData(result: any) {
    this.carMedia = result;

    this.carCode = this.car.carName.replace(/\s+/g, '-').toLowerCase();
    this.carCode = this.carCode.replace(/\./g, '-');
    this.carCode = this.carCode.replace(/\,/g, '-');
    this.carCode += "-" + this.car.id;

    this.image = "https://www.tonvandertuinautos.nl/api/images/" + this.carMedia.image.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "-thumbnail" + '$2');
  }

}
