import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Pages/home/home.component';
import { ContactComponent } from './Pages/contact/contact.component';
import { CollectieComponent } from './Pages/collectie/collectie.component';
import { AutoDetailComponent } from './Pages/auto-detail/auto-detail.component';
import { Error404Component } from './Pages/error404/error404.component';

const routes: Routes = [
  { path: 'collectie/:id', component: AutoDetailComponent},
  { path: 'collectie', component: CollectieComponent},
  { path: 'contact', component: ContactComponent},
  { path: '', component: HomeComponent },
  { path: '404', component: Error404Component },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
