import { Component, OnInit } from '@angular/core';
import { CarService } from '../../Shared/services/car.service';
import { Car } from '../../Shared/models/car';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-collectie',
  templateUrl: './collectie.component.html',
  styleUrls: ['./collectie.component.scss']
})
export class CollectieComponent implements OnInit {

  constructor(private carService: CarService, private titleSerice: Title, private meta: Meta) { }

  public allCars: Car[];

  ngOnInit() {
    this.carService.getCars().subscribe(result => this.allCars = result['data']);
    this.titleSerice.setTitle("Collectie - Ton van der Tuin Auto's");

    this.meta.updateTag({ property: 'og:image', content: "https://www.tonvandertuinautos.nl/assets/images/meta-image.jpg" });
    this.meta.updateTag({ property: 'og:title', content: "Collectie - Ton van der Tuin Auto's" });
    this.meta.updateTag({ property: 'og:url', content: "https://www.tonvandertuinautos.nl/#/collectie/" });
    this.meta.updateTag({ property: 'og:description', content: "In het aanbod van Ton van der Tuin vindt u dankzij zéér zorgvuldige selectie alleen de auto’s waar u eigenlijk naar op zoek bent. Met een sterke voorkeur voor de Duitse “premium brands”, en altijd auto’s met iets bijzonders." });
    this.meta.updateTag({ name: 'description', content: "In het aanbod van Ton van der Tuin vindt u dankzij zéér zorgvuldige selectie alleen de auto’s waar u eigenlijk naar op zoek bent. Met een sterke voorkeur voor de Duitse “premium brands”, en altijd auto’s met iets bijzonders." });

  }

}
