import { Component, OnInit } from '@angular/core';
import { Car } from '../../../Shared/models/car';
import { CarService } from '../../../Shared/services/car.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  constructor(private carService: CarService, private route: ActivatedRoute, private titleSerice: Title, private location: Location, private meta: Meta) { }

  public car: Car;
  private carId: number;
  private sub: any;
  public htmlDescription: string;

  public description: string;

  ngOnInit() {
    
    this.sub = this.route.params.subscribe(params => {
      let carCode = params['id'];
      var n = carCode.lastIndexOf('-');
      var result = carCode.substring(n + 1);
      this.carId = +result;
      this.carService.getCarById(this.carId).subscribe(result => this.bindDate(result['data']));
    });
  }

  bindDate(result: any) {
    this.car = result;
    this.titleSerice.setTitle(this.car.carName + " - Ton van der Tuin Auto's");
    var str = this.car.carName.replace(/\s+/g, '-').toLowerCase();
    //this.location.replaceState("/collectie/" + str);
    this.htmlDescription = this.car.description;
    var StrippedString = this.car.description.replace(/(<([^>]+)>)/ig, "");

    this.meta.updateTag({ name: 'description', content: StrippedString.substring(0, 170) });
    this.meta.updateTag({ property: 'og:description', content: StrippedString.substring(0, 170) });
  }

}
