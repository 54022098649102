import { Component, AfterViewInit } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
declare let ga: Function;

declare var clearNav: any;
declare var removeLg: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  async ngAfterViewInit(): Promise<void> {
    await this.delay(1500).then(result => this.curRoute = 2);
  }

  title = 'Ton van der Tuin';

  public curRoute = 0;

  async onActivate(event) {
    this.curRoute = 1;
    window.scroll(0, 0);
    removeLg();
    await this.delay(1500).then(result => this.curRoute = 0);
    clearNav();
    
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  constructor(public router: Router) {

    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

      }

    });

  }

}
