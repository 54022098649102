import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CarService } from '../../../Shared/services/car.service';
import { ContactModel } from '../../../Shared/models/contact';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  constructor(private carService: CarService, private formBuilder: FormBuilder) {

    this.contactForm = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.required],
      phone: [null],
      bodyMessage: [null, Validators.required],
    });
  }

  public sentStatus = false;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  error = '';
  contactForm: FormGroup;

  ngOnInit() {
    this.sentStatus = false;
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    this.loading = true;

    var contact = new ContactModel;
    contact.name = this.f.name.value;
    contact.email = this.f.email.value;
    contact.phone = this.f.phone.value;
    contact.bodyMessage = this.f.bodyMessage.value;

    this.carService.sendContact(contact).subscribe(result =>
      this.registerData(result['data'])
    );
  }

  registerData(res: any) {
    this.sentStatus = true;
    this.submitted = false;
    this.loading = false;
    this.contactForm.reset();
  }

}
