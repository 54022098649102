import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContactModel } from '../models/contact';

@Injectable({
  providedIn: 'root'
})
export class CarService {

  constructor(private http: HttpClient) { }

  private carsUrl = 'https://www.tonvandertuinautos.nl/api/cars';
  private singleCarUrl = 'https://www.tonvandertuinautos.nl/api/car';
  private highlightCarUrl = 'https://www.tonvandertuinautos.nl/api/highlight';

  private topSoldUrl = 'https://www.tonvandertuinautos.nl/api/carTopSold';
  private topNew = 'https://www.tonvandertuinautos.nl/api/carTopNew';
  private getImagesUrl = 'https://www.tonvandertuinautos.nl/api/getImages';
  private getPrimaryImageUrl = 'https://www.tonvandertuinautos.nl/api/getPrimaryImage';

  public contactUrl = 'https://www.tonvandertuinautos.nl/api/sendContact';

  public getCars(): Observable<any> {
    return this.http.get(this.carsUrl);
  }

  public getImagesForCar(carId: number): Observable<any> {
    const url = `${this.getImagesUrl}/${carId}`;
    return this.http.get(url);
  }

  public getPrimaryImage(carId: number): Observable<any> {
    const url = `${this.getPrimaryImageUrl}/${carId}`;
    return this.http.get(url);
  }

  public getCarById(carId: number): Observable<any> {
    const url = `${this.singleCarUrl}/${carId}`;
    return this.http.get(url);
  }

  public getHighlight(): Observable<any> {
    return this.http.get(this.highlightCarUrl);
  }

  public getNewestCars(): Observable<any> {
    return this.http.get(this.topNew);
  }

  public getSoldCars(): Observable<any> {
    return this.http.get(this.topSoldUrl);
  }

  sendContact(contact: ContactModel): Observable<any> {
    return this.http.post(this.contactUrl, {
      contact: contact
    });
  } 

}
