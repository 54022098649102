import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { CarService } from '../../../Shared/services/car.service';
import { CarMedia } from '../../../Shared/models/carMedia';
import { ActivatedRoute } from '@angular/router';
import { Car } from '../../../Shared/models/car';
import { Meta } from '@angular/platform-browser';
declare var lightGallery: any;


@Component({
  selector: 'app-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss']
})



export class DetailHeaderComponent implements OnInit {

  /*initLightGallery() {
    console.log("test");
    console.log(document.getElementById('lightGallery'));
    lightGallery(document.getElementById('lightGallery'));
  }*/

  @ViewChildren('CarImages') things: QueryList<any>;

  ngAfterViewInit() {
    this.things.changes.subscribe(t => {
      this.ngForRendred();
    })
  }

  ngForRendred() {
    lightGallery(document.getElementById('lightGallery'), { selector: '.item' });
  }

  constructor(private carService: CarService, private route: ActivatedRoute, private meta: Meta) { }

  public carImages: CarMedia[];
  public car: Car;
  private carId: number;
  private sub: any;
  public carImageLinks: string[];
  public carBigImages: string[];
  public carCode: string;

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.carCode = params['id'];
      var n = this.carCode.lastIndexOf('-');
      var result = this.carCode.substring(n + 1);
      this.carId = +result;
      this.carService.getCarById(this.carId).subscribe(result => this.registerData(result['data']));
    });

  }

  registerData(result:any) {
    this.car = result;
    this.carService.getImagesForCar(this.car.id).subscribe(result => this.fillImages(result['data']));
  }

  fillImages(result: any) {
    this.carImages = result;
    //var names = this.carImages.map(i => "/api/images/" + i.image); // live
    var names = this.carImages.map(i => "/api/images/" + i.image.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "-thumbnail" + '$2')); // dev
    var namesBig = this.carImages.map(i => "/api/images/" + i.image); // dev
    this.carImageLinks = names;
    this.carBigImages = namesBig;
    this.meta.updateTag({ property: 'og:image', content: "https://www.tonvandertuinautos.nl" + namesBig[0] });
    this.meta.updateTag({ property: 'og:title', content: this.car.carName + " - Ton van der Tuin Auto's"});
    this.meta.updateTag({ property: 'og:url', content: "https://www.tonvandertuinautos.nl/#/collectie/" + this.carCode });
  }

  myCarouselOptions = {
    center: true,
    nav: true,
    items: 1,
    loop: true,
    responsive: {
      600: {
        items: 2
      },
      1600: {
        items: 3
      }
    }
  }
}
