import { Component, OnInit } from '@angular/core';
import { CarService } from '../../../../Shared/services/car.service';
import { Car } from '../../../../Shared/models/car';

@Component({
  selector: 'app-car-details',
  templateUrl: './car-details.component.html',
  styleUrls: ['./car-details.component.scss']
})
export class CarDetailsComponent implements OnInit {

  constructor(private carService: CarService) { }

  public highlight: Car;
  public carCode: string;

  ngOnInit() {
    this.carService.getHighlight().subscribe(result => this.registerData(result['data']));

    
  }

  registerData(result:any) {

    this.highlight = result;

    this.carCode = this.highlight.carName.replace(/\s+/g, '-').toLowerCase();
    this.carCode = this.carCode.replace(/\./g, '-');
    this.carCode = this.carCode.replace(/\,/g, '-');
    this.carCode += "-" + this.highlight.id;

  }

}
