import { Component, OnInit } from '@angular/core';
import { CarService } from '../../services/car.service';
import { Car } from '../../models/car';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit {

  constructor(private carService: CarService) { }

  public newCars: Car[];

  ngOnInit() {
    this.carService.getNewestCars().subscribe(result => this.newCars = result['data']);
  }

}
