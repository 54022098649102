import { Component, OnInit } from '@angular/core';
import { CarService } from '../../../Shared/services/car.service';
import { Car } from '../../../Shared/models/car';

@Component({
  selector: 'app-collection-sold',
  templateUrl: './collection-sold.component.html',
  styleUrls: ['./collection-sold.component.scss']
})
export class CollectionSoldComponent implements OnInit {

  constructor(private carService: CarService) { }

  public soldCars: Car[];

  ngOnInit() {
    this.carService.getSoldCars().subscribe(result => this.soldCars = result['data']);
  }

}
