import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { user } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }

  private registerUrl = 'https://www.tonvandertuinautos.nl/api/register';

  registerNewUser(): Observable<any> {
    var usr = new user();

    return this.http.post(this.registerUrl, {
      user: usr
    });
  } 

}
