import { Component, OnInit } from '@angular/core';
import { CarService } from '../../../Shared/services/car.service';
import { Car } from '../../../Shared/models/car';
import { CarMedia } from '../../../Shared/models/carMedia';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  constructor(private carService: CarService) { }

  public highlight: Car;
  public carMedia: CarMedia;

  ngOnInit() {
    this.carService.getHighlight().subscribe(result => this.registerData(result['data']));
  }

  public registerData(result: any) {
    this.highlight = result;
    this.carService.getPrimaryImage(this.highlight.id).subscribe(result => this.carMedia = result['data']);
  }

}


